import { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";

import "./App.css";

function App() {
  let computeSnippet = `
  nodeSelector:
    nodeType: compute
  `;

  let memorySnippet = `
  nodeSelector:
    nodeType: memory
  tolerations:
  - key: nodeType
    operator: Equal
    value: memory
    effect: NoSchedule
  `;

  let highMemorySnippet = `
  nodeSelector:
    nodeType: high-memory
  tolerations:
  - key: nodeType
    operator: Equal
    value: high-memory
    effect: NoSchedule
  `;

  const [value, setValue] = useState("compute");
  const [snippetValue, setSnippetValue] = useState(computeSnippet);

  function handleSubmit(e) {
    const form = new FormData(e.target.form);
    const cpu = parseInt(form.get("cpu"));
    const memory = parseInt(form.get("memory"));

    const ratio = memory / cpu;

    console.log(`You have ${cpu} mCPU and ${memory} MiB of memory.`);
    console.log(`Your memory/cpu ratio is ${ratio}`);

    const sanitizeSnippet = (snippet) => {
      return snippet
        .split("\n")
        .filter((v) => v !== "")
        .join("\n");
    };

    if (ratio < 4) {
      setValue("compute");
      setSnippetValue(sanitizeSnippet(computeSnippet));
    } else if (ratio < 8) {
      setValue("memory");
      setSnippetValue(sanitizeSnippet(memorySnippet));
    } else if (ratio >= 8) {
      setSnippetValue(sanitizeSnippet(highMemorySnippet));
      setValue("high-memory");
    } else {
      setValue("fuck you");
    }
  }

  return (
    <div className="App">
      <header className="App-header bg-neutral-900">
        <div className="container max-w-2xl">
          <h1 className="text-3xl font-bold text-center">
            K8S Resources recommender for Squads
          </h1>
          <img
            src="https://cdn.streamelements.net/squads/shared-infra.png"
            className="logo"
            alt="logo"
          />
        </div>
        <div className="flex flex-row container my-10 gap-8 max-w-2xl text-center">
          <div
            className={[
              "rounded p-4 bg-neutral-800 font-bold text-lg grow",
              value === "compute" ? "glow" : "opacity-30",
            ].join(" ")}
          >
            Compute
          </div>
          <div
            className={[
              "rounded p-4 bg-neutral-800 font-bold text-lg grow",
              value === "memory" ? "glow" : "opacity-30",
            ].join(" ")}
          >
            Memory
          </div>
          <div
            className={[
              "rounded p-4 bg-neutral-800 font-bold text-lg grow",
              value === "high-memory" ? "glow" : "opacity-30",
            ].join(" ")}
          >
            High Memory
          </div>
          <div
            className={[
              "rounded p-4 bg-neutral-800 font-bold text-lg grow",
              value === "spot" ? "glow" : "opacity-30",
            ].join(" ")}
          >
            Spot
          </div>
        </div>
        <div className="container max-w-2xl mx-auto p-8 bg-neutral-800 text-lg">
          <form>
            <label className="block text-white-700 font-bold mb-2 text-base">
              CPU Request (mCPU)
              <input
                onChange={handleSubmit}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                name="cpu"
                type="number"
                defaultValue={100}
                placeholder="250 mCPU"
              />
            </label>
            <label className="block text-white-700 font-bold mb-2 text-lg">
              Memory Request (MiB)
              <input
                onChange={handleSubmit}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                name="memory"
                type="number"
                defaultValue={256}
                placeholder="250 MiB"
              />
            </label>
          </form>
          <div className="divide-y divide-violet-700" />
          <div>
            <label className="block text-white-700 font-bold mb-2 text-base">
              Yaml Snippet
              <SyntaxHighlighter
                className="snippet"
                language="yaml"
                style={github}
              >
                {snippetValue}
              </SyntaxHighlighter>
            </label>
          </div>
        </div>
        <p className="text-center text-white-700 text-sm mt-5 text-bold">
          Copyright© Shared Infrastructure. All rights reserved.
        </p>
      </header>
    </div>
  );
}

export default App;
